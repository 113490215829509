<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <tchtree v-model="searchForm.office_id"></tchtree>
        <el-input placeholder="姓名/学号" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:200px;" clearable>
        </el-input>
        <el-select v-model=searchForm.batch_id clearable size="small" placeholder="请选择实习批次" style="margin-left: 10px;">
          <el-option
              v-for="item in batchOptions"
              :key="item.id"
              :label="item.batch_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" style="margin-left:5px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList() }">搜索
        </el-button>

        <el-button style="margin-left:10px" size="small" type="primary"
                   @click="handleBatchAllocate">分配实习批次
        </el-button>
        <el-button style="margin-left:10px" size="small" type="primary"
                   @click="handleStuAllocate">分配学生实习
        </el-button>
      </div>
      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">

        <el-button icon="el-icon-download" style="margin-left:10px" size="small" type="text">
          <a href="/xls/实习分配导入模板.xls" style="color:inherit;">下载模板</a>
        </el-button>
        <el-upload style="display: inline-block; margin-right: 10px" action :http-request="uploadTemp"
                   :on-success="uploadFiles" :show-file-list="false" name="image">
          <el-button icon="el-icon-position" style="margin-left:10px" size="small" @click="getList" type="primary"
                     plain>导入</el-button>
        </el-upload>
      </div>
    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit
              ref="multipleTable"
              @selection-change="handleSelectionChange"
              :data="tableData"
              row-key="id"
              height="100%" size="small" stripe border
              style="width:fit-content;width:100%;">
      <el-table-column type="selection" :reserve-selection="true" width="55" align="center" />
      <el-table-column type="index" label="序号" width="55px" align="center"/>
      <el-table-column label="学号" prop="stu_code" show-overflow-tooltip width="150px"/>
      <el-table-column label="姓名" prop="stu_name" width="100px"/>
      <el-table-column label="性别" prop="gender"  width="100px">
        <template v-slot="scope">
          <el-tag v-if="scope.row.gender == 1" size="small">男</el-tag>
          <el-tag v-if="scope.row.gender == 2" type="danger" size="small">女</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="班级" prop="class_name" width="200px"/>
      <el-table-column label="届次" prop="jc_name" width="200px"/>
      <el-table-column label="院系" prop="yx_name" width="200px"/>
      <el-table-column label="手机号" prop="phone" width="200px"/>
      <el-table-column label="批次名称" prop="batch_name" min-width="200px"  show-overflow-tooltip align="center"/>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog :visible.sync="showAllocateDialog" :close-on-click-modal="false"
               custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">实习分配</span>
      </div>
      <el-form :model="formData" :rules="rules" ref="dynamicValidateForm" label-width="120px">
        <div style="text-align: right;"><el-button
            class="el-icon-plus"
            type="primary"
            size="mini"
            circle
            @click="addItem"
        ></el-button></div>
        <div v-for="(item,index) in formData.forms" :key="index">
          <div v-if="index > 0" style="text-align: right"><el-button class="el-icon-minus"
                                                                     type="danger"
                                                                     size="mini"
                                                                     circle
                                                                     @click="delItem(index)"
          ></el-button></div>
          <el-form-item label="实习起止时间"
                        :rules="rules.start_end_date"
                        :prop="`forms.${index}.start_end_date`">
            <el-date-picker
                v-model="item.start_end_date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实习医院"
                        :rules="rules.enter_id"
                        :prop="`forms.${index}.enter_id`">
            <el-select v-model="item.enter_id" placeholder="请选择实习医院" filterable>
              <el-option
                  v-for="item in enters"
                  :key="item.id"
                  :label="item.firm_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实习岗位" :prop="`forms.${index}.job`">
            <el-input v-model="item.job" placeholder="请输入实习岗位" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="职位" :prop="`forms.${index}.stu_position`">
            <el-select v-model="item.stu_position" placeholder="请选择职位">
              <el-option label="无职位" :value="0"></el-option>
              <el-option label="小组长" :value="1"></el-option>
              <el-option label="大组长" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAllocateDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";

export default {
  components: {tchtree},
  data() {
    return {
      activeName: "first",
      isRevoke: false,
      searchForm: {
        keyword: "",
        status: "",
        office_id: "",
        ktype: 'stu_name'
      },
      classArr: [],
      tableData: [],
      dialogForm: false,
      modalTitle: "添加",
      sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
      qyxz: [], qyxzObj: {},
      salary: [], salaryObj: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      overlap: null,
      spword: "",
      sxqxArr: [], sxqxObj: {},
      applyList: [],
      dialogExportFormVisible: false,
      dialogChangeVisible: false,
      export_format: "word",
      changeList: [],
      dateRangeArr: [],
      checkBtn: false,
      rules: {
        enter_id: [{required: true, message: '请选择实习医院', trigger: 'change'}],
        start_end_date: [{required: true, message: '请选择实习起止时间', trigger: 'change'}],
      },
      multipleSelection: [],
      batchOptions: [],
      showAllocateDialog: false,
      enters: [],
      formData: {
        forms: [
          {
            start_end_date: [],
            enter_id: "",
            job: "",
            stu_position: 0
          }
        ],
      }
    };
  },
  mounted() {
    this.getFieldworkBatch()
    this.getEnters()
    this.getList();
  },
  methods: {
    getFieldworkBatch() {
      this.$http
          .post("/api/fieldwork_batch_list", {pagesize: 10000})
          .then(res => {
            this.batchOptions = res.data.data
          });
    },
    getEnters() {
      this.$http
          .post("/api/enterprise_list", {pagesize: 10000})
          .then(res => {
            this.enters = res.data.data
          });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    handleAdd() {
      this.dialogForm = true;
      this.modalTitle = "新增实习批次";
      this.formData = {
        complete_week: 1,
        complete_month: 1,
        dk_count: 1,
        xf_count: 1,
      }
      this.resetForm()
    },
    getList() {
      let data = {};
      data.keyword = this.searchForm.keyword;
      data.ktype = this.searchForm.ktype
      data.office_id = this.searchForm.office_id
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      this.$http.post("/api/t_sch_students_list", data).then(res => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: 'warning'
      }).then(res => {
        this.$http
            .post("/api/fieldwork_batch_delete", {
              id: row.id
            })
            .then(res => {
              this.$message.success("删除成功");
              this.getList();
            });
      })
    },
    handleModify(row) {
      this.modalTitle = "编辑实习批次"
      this.formData = {...row}
      this.formData.start_end_date = [row.start_time,row.end_time]
      this.dialogForm = true
    },
    submitForm() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          let formData = [...this.formData.forms]
          formData.map(f => {
            f.start_time = f.start_end_date[0]
            f.end_time = f.start_end_date[1]
          })
          let stuIds = ""
          let msg = "此操作将<strong style='color:#ff0000'>全部学生</strong>分配到指定医院"
          if (this.multipleSelection.length) {
            stuIds = this.multipleSelection.map(m => m.id).join(',')
            msg = "此操作将<strong style='color: #ff0000'>所选学生</strong>分配到指定医院"
          }
          this.$confirm(`${msg}，是否继续？`, {
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(() => {
            this.$http.post('/api/stu_internship_allocate', {
              internships: JSON.stringify(formData),
              stu_ids: stuIds,
              office_id: this.searchForm.office_id,
              keyword: this.searchForm.keyword,
              ktype: this.searchForm.ktype,
            }).then(res => {
              if (res.data && res.data.errors.length) {
                let _html = ""
                res.data.errors.forEach(msg => {
                  _html += `<span>${msg}</span><br/>`
                })
                this.$alert(_html,'', {
                  dangerouslyUseHTMLString: true,
                })
              } else {
                this.$message.success('提交成功')
              }
              this.showAllocateDialog = false
              this.$refs.multipleTable.clearSelection();
              this.getList()
            })
          })

        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dynamicValidateForm.resetFields();
    },
    addItem() {
      this.formData.forms.push({
        start_end_date: [],
        enter_id: "",
        job: "",
        stu_position: 0
      })
    },
    delItem(index) {
      this.formData.forms.splice(index, 1)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleBatchAllocate() {
      if (!this.searchForm.batch_id) {
        return this.$message.warning('请选择实习批次')
      }
      let msg = "此操作将<strong style='color:#ff0000'>全部学生</strong>分配到指定批次"
      if (this.multipleSelection.length > 0) {
        msg = "此操作将<strong style='color: #ff0000'>所选学生</strong>分配到指定批次"
      }
      this.$confirm(`${msg}，是否继续？`,{
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(()=>{
        let stu_ids = ""
        if (this.multipleSelection.length > 0) {
          stu_ids = this.multipleSelection.map(m => m.id).join(',')
        }
        this.$http.post('/api/allocate_fieldwork_batch', {
          stu_ids: stu_ids,
          batch_id: this.searchForm.batch_id,
          office_id: this.searchForm.office_id,
          keyword: this.searchForm.keyword,
          ktype: this.searchForm.ktype,
        }).then(res => {
          this.$message.success('分配成功')
          this.getList()
        })
      })
    },
    handleStuAllocate() {
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        this.$message.warning('请先至少选择一位学生，再分配实习~')
      } else {
        this.showAllocateDialog = true
        this.formData.forms = [
          {
            start_end_date: [],
            enter_id: "",
            job: "",
            stu_position: 0
          }
        ]
        if (this.$refs.dynamicValidateForm) {
          this.resetForm()
        }
      }
    },
    uploadFiles(e) {
      this.$http
          .post("/api/stu_internship_allocate_import", {
            url: e.src
          })
          .then(res => {
            this.$message.success("导入成功");
            if (res.data.errors && res.data.errors.length > 0) {
              let html = "";
              for (let msg of res.data.errors) {
                html +=
                    "<div style='font-size:12px'>" + msg + "</div>";
              }
              this.$alert(html, "未导入数据", {
                dangerouslyUseHTMLString: true
              });
            }
            this.getList();
          });
    },
  }
};
</script>

<style scoped lang="less">
</style>